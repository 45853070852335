@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

* {
     padding: 0;
    margin: 0; 
    font-family: "DM Sans", sans-serif !important;
}

body {
    background-color: #FFFFFF !important;

}

.Layout {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-image: url("../../assets/images/LandingImage.png");
    background-position: center;
    width: 100%;
    height: 100vh;
}

.Login-Menu {
    width: 1512px;
    flex-shrink: 0;
    height: 60px !important;
    padding: 2px !important;
    background: linear-gradient(108.5deg, rgba(255, 255, 255, 0.138) -55.97%, rgba(255, 255, 255, 0.138) 110.52%) !important;
    backdrop-filter: blur(32px);
}

.talentxchangelogo {
    width: 200px;
    height: 48px;
    flex-shrink: 0;
}

.main-content {
    color: #FFFFFF;
    text-align: center;
    margin: auto;
    padding: auto;
    text-align: center;
    width: 80%;
    font-family: "DM Sans", sans-serif;
    font-style: normal;
    text-transform: capitalize;
    padding-top: 20px !important;

}

.main-text {
    margin-top: 9% !important;
    font-size: 60px !important;
    font-weight: 600 !important;
    line-height: normal;
    letter-spacing: -1.2px
}

.us-talent {
    background: linear-gradient(86.99deg, #9D42F1 47.05%, #0FF06D 58.64%);
    -webkit-text-fill-color: transparent;
    background-clip: text;
    line-height: normal;
    letter-spacing: -1.2px
}

.powerdby {
    padding-top: -15px !important;
    color: #FFF;
    font-size: 20px !important;
    font-weight: 200 !important;
    letter-spacing: -0.4px;
    line-height: normal;
    letter-spacing: -0.4px !important;


}

.text {
    color: #FFF;
    margin: 5px;
    padding: 5px !important;
    word-wrap: break-word;
    text-shadow: 13px 4px 6.3px rgba(0, 0, 0, 0.40);
    font-family: "DM Sans", sans-serif;
    font-size: 26px !important;
    font-weight: 300 !important;
    width: 600px;
    margin: auto;
    padding-top: 9vh !important;
    text-transform: capitalize;
    line-height: normal;
    letter-spacing: -0.52px;


}

.Getstarted {
    position: absolute !important;
    color: #FFFFFF !important;
    width: 150px;
    height: 45px;
    bottom: 9vh;
    right: 44%;
    border-radius: 60px !important;
    background: rgba(255, 255, 255, 0.01) !important;
    box-shadow: 0px 39px 56px -36px rgba(255, 255, 255, 0.50) inset, 0px 7px 11px -4px #FFF inset, 0px -82px 68px -64px rgba(15, 240, 109, 0.30) inset, 0px 98px 100px -48px rgba(157, 66, 241, 0.30) inset, 0px 4px 18px 0px rgba(122, 51, 187, 0.30) inset, 0px 1px 40px 0px rgba(69, 242, 141, 0.40) inset !important;
    backdrop-filter: blur(12.5px) !important;
    line-height: normal;
    letter-spacing: -0.44px;

}



.loginbutton {
    background-color: #0FF06D !important;
    color: black !important;
    width: 90px !important;
    height: 35px !important;
    padding: 8px 28px !important;
    justify-content: center;
    margin-right: 15px !important;

}

.signup {
    display: flex;
    width: 90px;
    height: 37px;
    padding: 8px 28px !important;
    justify-content: center;
    align-items: center;
    border-radius: 16px !important;
    border: 2px solid #0FF06D !important;
    color: #0FF06D !important;
    background-color: rgb(88, 88, 88) !important;
    gap: 10px;
}

.nav-button {
    font-weight: 100;
}

/* page2 */
.page2background {
    background-color: #160B30;
    color: #FFFFFF;
    width: 100%;
    height: 100%;
}

.Menubar {
    display: flex;
    position: absolute;
    width: 49%;
    height: 50px;
    justify-content: center;
    text-align: center;
    margin-left: 25% !important;
    margin-bottom: auto !important;
    margin-right: 25% !important;
    padding: auto !important;
    margin-top: 50px !important;
    gap: 16px;
    border-radius: 53px;
    background: var(--Main-Gradient, linear-gradient(113deg, rgba(255, 255, 255, 0.47) 0%, rgba(255, 255, 255, 0.00) 110.84%));
    backdrop-filter: blur(18px);
}

.maincontent {
    display: flex;
    align-items: center;
    gap: 2% !important;
    margin-top: 0 !important;
    padding-top: 15%;
    padding-left: 6% !important;
    padding-bottom: 4% !important;
}

.imageContainer {
    width: 1100px !important;
    height: 600px !important;
    padding-top: 10% !important;
    margin-left: 4% !important;
    margin-bottom: 0px !important;
    padding-bottom: opx !important;
}

.gridcontent {
    margin-bottom: -20% !important;
}

.description {
    word-wrap: break-word;
    text-align: justify;
}



.signincontent {
    margin: auto !important;
    padding: auto !important;
}

.talentcvhange {
    color: #0FF06D;
}

/* page3 */
.container-page3 {
    padding-top: 5% !important;
}

.mainp3 {
    width: 750px;
    color: #0FF06D;
    text-align: center;
    font-size: 40px !important;
    font-style: normal;
    font-weight: 800 !important;
    line-height: normal;
    letter-spacing: -1.2px !important;
    margin: auto !important;
}

.maincontent-page3 {
    width: auto !important;
    height: 450px;
    padding: 0% !important;
    margin: 0% !important;
    margin-left: 7% !important
}

.page3Heading {
    color: #FFF !important;
    font-size: 34px !important;
    font-style: normal !important;
    font-weight: 500 !important;
    line-height: normal !important;
    letter-spacing: -0.68px !important;
    margin-top: 15px !important;
    margin-bottom: 10px !important;
    text-align: left !important;
    margin-left: 11% !important;
}

.image1p3 {
    width:450px;
    margin: -4% !important;
    padding: 5px !important;
}

.image2p3 {
    width: 450px;
    margin-left: -228px;
}


.imagegrid {
    display: grid;
    margin-left: 9% !important;
    margin-right: 10px !important;
}

.rightHeading {
    color: #FFF !important;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
    letter-spacing: -0.6px !important;
}

.maincontent-page3 P {
    font-size: 15px;
    font-weight: 200 !important;
    letter-spacing: -0.4px !important;
}

.left {
    margin-top: 10px !important;
}

.page2image2 {
    margin: 0px !important;
    padding: 0px !important;
    width: 300px;
    height: 300px;
}

/* page4 */

.container-page4 {
    width: auto;
    height: 100vh;
    margin-top: 0;
    padding-top: 6%;
}

.maincontentp4 {
    display: flex;
    gap: 3% !important;
    margin: 15%;
    margin-top: 0;
    padding: auto !important;
    font-family: "DM Sans", sans-serif !important;

}

.contactHeading {
    color: #0FF06D;
    text-align: left !important;
    font-size: 40px !important;
    font-style: normal;
    font-weight: 400 !important;
    margin-bottom: 20% !important;

}

.emailandInfo {
    width: 350px;
    font-size: 19px !important;
    font-weight: 200 !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;

}

.contactp4 {
    font-size: 20px !important;
    font-weight: 200 !important;
    line-height: 34px !important;
}

.LoginandSubmit {
    margin-top: 22% !important;
}

.LetsGetstarted {
    font-size: 20px !important;
    font-weight: 200 !important;
    line-height: 34px !important;
    margin-bottom: 0 !important;
}

.loginGroup {
    display: flex;
    gap: 25px;
    justify-content: left;
    padding-top: 0 !important;
    font-family: "DM Sans", sans-serif !important;


}

.LoginP4 {
    width: 110px;
    height: 30px;
    background-color: #9D42F1 !important;
    color: #FFF !important;
    font-size: 17px !important;
    font-weight: 100 !important;
    border-radius: 16px !important;


}

.SignupP4 {
    width: 110px;
    height: 30px;
    border: 1px #9D42F1 solid !important;
    font-size: 17px !important;
    font-weight: 200 !important;
    border-radius: 16px !important;
    color: #9D42F1 !important;

}


.formforcontact {
    border: 2px solid rgba(255,255,255,0.3) !important;
    border-radius: 10px;
background: linear-gradient(104deg, rgba(157, 66, 241, 0.20) 0%, rgba(92, 109, 255, 0.20) 77.6%), rgba(29, 29, 29, 0.05);
   
    position: absolute;
    right: 5%;
    padding: 2%;
    margin-right: 0;
    margin-top: -5px;
    border-radius: 31px;
}


.getintouch {
    margin-top: -15% !important;
    margin-bottom: 15px !important;
    font-weight: 400 !important;

}

.formtext2 {
    font-family: "DM Sans", sans-serif !important;
    margin-bottom: 2% !important;
    font-size: 16px !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


/* pag4 */
.faqform {
    width: 80%;
    height: auto;
    margin: auto;
    padding: auto;
    margin-top: 5%;
    padding-bottom: 4%;
}

.p4bottom {
    display: flex;
    justify-content: center;
    gap: 5% !important;
    padding-bottom: 5%;
}

.p4bottomtext {
    color: #FFF !important;
    border-radius: 60px !important;
    background: rgba(255, 255, 255, 0.01) !important;
    box-shadow: 0px 39px 56px -36px rgba(255, 255, 255, 0.50) inset, 0px 7px 11px -4px #FFF inset, 0px -82px 68px -64px rgba(15, 240, 109, 0.30) inset, 0px 98px 100px -48px rgba(157, 66, 241, 0.30) inset, 0px 4px 18px 0px rgba(122, 51, 187, 0.30) inset, 0px 1px 40px 0px rgba(69, 242, 141, 0.40) inset !important;
    backdrop-filter: blur(12px) !important;
    line-height: normal;
    letter-spacing: -0.44px;
    border: none;
}

.accTitle {

    font-size: 20px !important;

}

.textvas {
    color: #FFF;
    text-align: center;
    word-wrap: break-word;
    text-shadow: 13px 4px 6.3px rgba(0, 0, 0, 0.40);
    font-family: "DM Sans", sans-serif;
    font-size: 20px;
    font-weight: 400;
    width: 650px;
    margin: auto !important;
    margin-top: -10px !important;
    padding-top: 9vh !important;
    text-transform: capitalize !important;
    line-height: normal;
    letter-spacing: -0.52px;
}

.comngsoon {
    text-align: center;
    padding-bottom:5px !important;
    margin-top: 15px;
}

.cardgridvas {
    display: flex;
    gap: 5%;
    margin: 6% !important;
    padding: 6% !important;
    padding-top: 1px  !important;
    margin-top: 0;
    margin-bottom: 0 !important;
}

.testimonyvas {
    color: #0FF06D;
    text-align: center !important;
    font-size: 40px !important;
    font-style: normal;
    font-weight: 400 !important;
    margin-bottom: 2% !important;
}

/* //p5 */
.cardcomponent {
    background-color: rgba(255, 255, 255, 0.1);
    color: #FFF;

}


.cardfooter {
background: linear-gradient(122deg, #44276B 27.71%, #CEB3E7 161.15%);
backdrop-filter: blur(21px);
color:'#fff';
}
.cardfooter1{
background: linear-gradient(184deg, #CEB3E7 -9.35%, #44276B 162.7%);
box-shadow: 0px -24px 24.6px 0px rgba(64, 33, 104, 0.31);
backdrop-filter: blur(21px);

}

/* //p6 */
.mainTX {
    width: 750px;
    color: #0FF06D;
    text-align: justify;
    font-size: 40px !important;
    font-style: normal;
    font-weight: 800 !important;
    line-height: normal;
    letter-spacing: -1.2px !important;
    padding-top: 20px !important;
    margin-left: 6% !important;

}

.descriptiontx {
    width: 550px !important;
    word-wrap: break-word;
    text-align: left;
    margin-left: 6% !important;
    font-size: 20px !important;
    font-weight: 500 !important;
    line-height: normal;
    letter-spacing: -0.6px !important;
    text-transform: capitalize;


}

.txch {
    background: linear-gradient(180deg, #160B30 58.37%, #6B2CA5 100%), #FFF !important;
}

.TXimagecontainer {
    display: flex;
    justify-content: space-evenly;
    gap: 4%px
}

.tximg1 {
    width: 550px !important;
    margin-top: -12% !important;
}

.employerimg {
    margin-top: 12% !important;
    padding-top: 5% !important;
}

.vendorimg {    
    margin-top: 12% !important;
    padding-top: 5% !important;
}

.txtimg2 {
    width: 600px !important;
    margin-top: -12% !important;
}
.muisubmit{
    border-radius: 31px;
background:linear-gradient(180deg, rgba(157, 66, 241, 0.67) -17.14%, rgba(15, 240, 109, 0.84) 137.14%);
box-shadow: 0px 4.598px 6.897px 0px rgba(255, 255, 255, 0.15) inset;
backdrop-filter: blur(7);
/* z-index: 10000; */
}
.customField::placeholder{
    color:#fbfbfb;
    opacity: 0.7;
}
